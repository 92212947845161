.NoRisk {
    /* display: none; */
    flex-direction: column;
    padding: .5rem;
    text-align: left;
}

.Factor{
    color: darkorange;
    font-weight: bold;
    padding: .25rem;
}

.ThisRisk > .Factor{
    color: #f1ff00;
    font-weight: bold;
    padding: .25rem;
}

.NonFactor {
    color: #555555;
    padding: .25rem;
}

.ThisRisk {
    display: flex;
    background: #4e48e9;
    color: #fff;
}

.ThisRisk > .NonFactor{
    color: white;
}
