.Backdrop {
    position: fixed;
    z-index: 49;
    top: 0;
    left: 0;
    width: 100%;
    height: 1000%;
    background-color: rgba(0,0,0,0.7);
    transform: translateZ(100);
}

.BackdropOpen {
    display: block;
}

.BackdropClosed {
    display: none;
}

.NewBackButton {
    position: absolute;
    right: -4rem;
    top: -1rem;
    font-size: 1rem;
    transition: all 120ms ease-in;
}

.Modal {
    z-index: 200;
    top: 20%;
    left: 10%;
    transition: all 0.3s ease-out;
}

.ModalOpen {
    animation: openModal 0.4s ease-out forwards;
}

.ModalClosed {
    animation: closeModal 1s ease-out forwards;
}

.fade-slide-enter-active {
    animation: openModal 0.4s ease-out forwards;
}

.fade-slide-exit-active {
    animation: closeModal 1s ease-out forwards;
}

@keyframes openModal {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes closeModal {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}
