.FadeCopied{
    animation: fadeIn 2s linear 0s forwards;
}

@keyframes fadeIn {
    0% {opacity: 0;}
    25% {opacity: 1;}
    50% {opacity: 1;}
    100% {opacity: 0;}
}

.AnalysisModal {
    height: 90vh;
    top: 5%;
    left: 7.5%;
    width: 85%;
    z-index: 200;
    transition: all 0.3s ease-out;
}
