.InfoModal{
    top: 5%;
    height: 90vh;
    line-height: 1.6rem;
    font-size: 1rem;
    left: 20%;
    width: 60%;
}

.InfoModal h2{
    text-align: center;
}

.InfoTextWrapper{
    margin: auto;
}

.InfoModal table {
    border-collapse: collapse;
    border: 1px solid gray;
    margin: 1rem 0;
}

.InfoModal td{
    border: 1px solid gray;
    min-width: 4rem;
    padding: .5rem;
}

.InfoModal th{
    border: 1px solid gray;
    min-width: 4rem;
    padding: .5rem;
}